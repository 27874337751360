import {
  InvitationRequesterRole,
  InvitationType,
  InviteSupplierData,
  Mapping,
  SupplierContact,
  UpdateMappingStatePayload
} from './types'
import { OptionItem, TreeNode, subAccount } from 'src/types'
import { PayloadAction, Reducer, createSlice } from '@reduxjs/toolkit'

export interface InvitationState {
  workspaces: OptionItem[]
  workspaceId: string | null
  rootFolderId: string | null
  folders: TreeNode[]
  countries: OptionItem[]
  industries: OptionItem[]
  modules: OptionItem[]
  inviteSupplier: InviteSupplierData
  selectedWorkspaces: subAccount[]
  currentWorkspace: Mapping
  samePermissions: boolean
}

const inviteSupplierInitialData: InviteSupplierData = {
  type: InvitationType.ClientSupplier,
  as: InvitationRequesterRole.SupplierNew,
  account_uuid: '',
  status: '',
  comments: '',
  account_name: '',
  contact: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    country_code: ''
  },
  consumer_contact: undefined,
  host_contact: undefined,
  company_name: '',
  folder_uuid: '',
  industry_uuid: '',
  modules: [],
  mappings: undefined,
  consumerAccounts: undefined,
  subscription_uuid: ''
}

export const initialState: InvitationState = {
  workspaces: [],
  workspaceId: null,
  rootFolderId: null,
  folders: [],
  countries: [],
  industries: [],
  modules: [],
  inviteSupplier: inviteSupplierInitialData,
  currentWorkspace: undefined,
  selectedWorkspaces: [],
  samePermissions: null
}

const invitationSlice = createSlice({
  name: 'invitation',
  initialState,
  reducers: {
    resetState: () => initialState,
    setInvitation: (state, action: PayloadAction<InviteSupplierData>) => {
      state.inviteSupplier = action.payload
    },
    setInvitationId: (state, action: PayloadAction<string>) => {
      state.inviteSupplier.id = action.payload
    },
    updateWorkspaces: (state, action: PayloadAction<OptionItem[]>) => {
      state.workspaces = action.payload
    },
    updateCurrentClientWorkspace: (state, action: PayloadAction<Mapping>) => {
      state.currentWorkspace = action.payload
    },
    resetCurrentClientWorkspace: (state) => {
      state.currentWorkspace = undefined
    },
    updateSelectedWorkspaces: (state, action: PayloadAction<subAccount[]>) => {
      state.selectedWorkspaces = action.payload
    },
    updateSamePermissions: (state, action: PayloadAction<boolean>) => {
      state.samePermissions = action.payload
    },
    updateWorkspaceId: (state, action: PayloadAction<string>) => {
      state.rootFolderId = null
      state.workspaceId = action.payload
    },
    updateRootFolderId: (state, action: PayloadAction<string>) => {
      if (state.rootFolderId !== action.payload) state.rootFolderId = action.payload
    },
    updateFolders: (state, action: PayloadAction<TreeNode[]>) => {
      state.folders = action.payload
    },
    updateCountries: (state, action: PayloadAction<OptionItem[]>) => {
      state.countries = action.payload
    },
    updateIndustries: (state, action: PayloadAction<OptionItem[]>) => {
      state.industries = action.payload
    },
    updateModules: (state, action: PayloadAction<OptionItem[]>) => {
      state.modules = action.payload
    },
    updateMappingState: (state, action: PayloadAction<UpdateMappingStatePayload>) => {
      const { account_uuid, newState } = action.payload

      const mappingIndex = state.inviteSupplier.mappings?.findIndex((mapping) => mapping.account_uuid === account_uuid)
      if (mappingIndex !== undefined && mappingIndex !== -1) {
        state.inviteSupplier.mappings[mappingIndex].states = newState
      }
    },
    updateMapping: (state, action: PayloadAction<Mapping[]>) => {
      state.inviteSupplier.mappings = action.payload
    },
    updateInviteSupplier: (state, action: PayloadAction<{ key: keyof InviteSupplierData; value: any }>) => {
      const { key, value } = action.payload
      if (key in state.inviteSupplier) {
        // @ts-ignore
        state.inviteSupplier[key] = value
      }
    },
    updateInviteSupplierHostContact: (state, action: PayloadAction<{ key: keyof SupplierContact; value: any }>) => {
      const { key, value } = action.payload
      if (key in state.inviteSupplier.host_contact) {
        // @ts-ignore
        state.inviteSupplier.host_contact[key] = value
      }
    },
    updateInviteSupplierContact: (state, action: PayloadAction<{ key: keyof SupplierContact; value: any }>) => {
      const { key, value } = action.payload
      if (key in state.inviteSupplier.contact) {
        // @ts-ignore
        state.inviteSupplier.contact[key] = value
      }
    }
  }
})

export const {
  resetState,
  setInvitation,
  setInvitationId,
  updateInviteSupplier,
  updateInviteSupplierContact,
  updateInviteSupplierHostContact,
  updateWorkspaces,
  updateCurrentClientWorkspace,
  resetCurrentClientWorkspace,
  updateSelectedWorkspaces,
  updateSamePermissions,
  updateFolders,
  updateWorkspaceId,
  updateRootFolderId,
  updateCountries,
  updateIndustries,
  updateModules,
  updateMappingState,
  updateMapping
} = invitationSlice.actions

const invitationReducer: Reducer<InvitationState> = invitationSlice.reducer

export default invitationReducer
