/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useState, useEffect, useMemo } from 'react'
import {
  Box,
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import { CloseIcon } from '@papertrail/styleguide'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import {
  resetState,
  updateRootFolderId,
  updateWorkspaceId,
  updateWorkspaces,
  updateFolders,
  setInvitation
} from '../../../state/slices/invitation'
import { useSnackbar } from '../../providers/snackbar'
import { useParams } from 'react-router-dom'
import { useApiGetAccount, useApiGetFolders, useApiGetSubAccounts, useApiPatchPartnership } from '../../../hooks'
import CustomTabs from '../../molecules/tab'
import CustomTreeView from '../threeview'
import MapStates from '../detail/supplierReview/mapStates'

interface IDrawerPartnershipDetailProps {
  isOpened: boolean
  setIsOpened: (state: boolean) => any
  children?: ReactNode
}

const DrawerPartnershipDetail: React.FC<IDrawerPartnershipDetailProps> = ({
  isOpened,
  setIsOpened
}: IDrawerPartnershipDetailProps) => {
  const dispatch = useDispatch()

  const [currentTab, setCurrentTab] = useState('FOLDER-LOCATION')
  const { showSnackbar } = useSnackbar()
  const { workspaces, workspaceId, rootFolderId, folders, inviteSupplier } = useSelector(
    (state: RootState) => state.invitation
  )
  const [accountState, loadAccount] = useApiGetAccount(workspaceId)
  const [subAccountsState, loadSubAccounts] = useApiGetSubAccounts()
  const [folderList, loadFolders] = useApiGetFolders(workspaceId, rootFolderId, folders)
  const { id: subscriptionId } = useParams<{ id: string }>()
  const [_, updatePartnership] = useApiPatchPartnership(subscriptionId, inviteSupplier.id)

  const [currentFolderId, setCurrentFolderId] = useState('')

  const TAB_STATES = [
    { value: 'FOLDER-LOCATION', label: 'Folder Location' },
    { value: 'STATE-MAPPING', label: 'State Mapping' }
  ]

  const handleWorkspaceChange = (event: SelectChangeEvent<any>) => {
    // SAVE LAST WORKSPACE ID
    localStorage.setItem('@LAST_WORKSPACE_ID', event.target.value)

    dispatch(updateWorkspaceId(event.target.value))
  }

  const handleCloseDrawer = () => {
    dispatch(resetState())
    setCurrentTab('FOLDER-LOCATION')
    setIsOpened(false)
  }

  const handleSetInitialWorkspace = () => {
    if (inviteSupplier?.host_contact?.account_id && isOpened) {
      dispatch(updateWorkspaceId(inviteSupplier?.host_contact?.account_id))
    }
  }

  const handleSaveChanges = async () => {
    try {
      await updatePartnership({
        host_folder_uuid: currentFolderId,
        mappings: inviteSupplier.mappings
      })
      await setTimeout(() => {
        // @TODO: adjust the company name here
        showSnackbar(
          `You have successfully edited the ${
            currentTab === 'FOLDER-LOCATION' ? 'Folder Location' : 'State Mapping'
          } of ${inviteSupplier.company_name} in ${workspace.label}.`,
          'success'
        )
        window.dispatchEvent(new Event('@invites/GET_ALL'))
        handleCloseDrawer()
      }, 300)

      // close modal
    } catch (err) {
      showSnackbar(err.message, 'error')
    }
  }

  /* EFFECTS */
  //1. Fetch the workspaces/accounts from a subscription if subscriptionId is present
  useEffect(() => {
    if (subscriptionId && !workspaces.length) {
      loadSubAccounts({ subscription_id: subscriptionId, role: 'member,owner,employee' })
    }
  }, [subscriptionId, workspaces])

  //2. When accounts/workspaces are loaded, populate the workspaces list
  useEffect(() => {
    if (
      subAccountsState.isLoaded &&
      subAccountsState.data.data &&
      subAccountsState.data.data.length &&
      !workspaces?.length &&
      isOpened === true
    ) {
      const workspacesList = subAccountsState.data?.data.map((account) => ({
        label: account.name,
        value: account.id
      }))
      dispatch(updateWorkspaces(workspacesList))
    }
  }, [subAccountsState.isLoaded, isOpened])

  //3. Fetch the workspace if workspaceId is changed
  useEffect(() => {
    if (workspaceId) loadAccount({ include: 'folder', role: 'member,owner,employee' })
  }, [workspaceId])

  //4. Load folders when account/workspace is fetched and root folder is available
  useEffect(() => {
    // reset folders
    dispatch(updateFolders([]))
    if (accountState.isLoaded && accountState.data?.data?.folder?.data?.id && isOpened === true) {
      dispatch(updateRootFolderId(accountState.data?.data?.folder?.data?.id))
      const account = accountState.data.data
      const workspacesList = [{ label: account.name, value: account.id }]
      dispatch(updateWorkspaces(workspacesList))
    }
  }, [accountState, workspaceId, isOpened])

  useEffect(() => {
    if (rootFolderId) {
      loadFolders({ include: `descendants`, format: 'tree' })
    }
  }, [rootFolderId])

  //5. When folder list is loaded, update the Redux store
  useEffect(() => {
    if (folderList.isLoaded && folderList.data) {
      dispatch(updateFolders(folderList.data))
    }
  }, [folderList])

  // 6. When invite/partnership object change, set current folder
  useEffect(() => {
    if (inviteSupplier && isOpened) {
      setCurrentFolderId(inviteSupplier?.host_folder_uuid)
      handleSetInitialWorkspace()
    }
  }, [inviteSupplier, isOpened])

  // MEMOS
  const workspace = useMemo(() => {
    return workspaces?.find((w) => w.value === workspaceId)
  }, [workspaces, workspaceId])

  const renderFolders = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}>
        <Typography
          variant="h6"
          sx={{
            color: 'rgba(0, 0, 0, 0.87)'
          }}>
          You are viewing the Partner Permissions between {workspace?.label} and {inviteSupplier.company_name}.
        </Typography>
        <Typography>
          Choose where to place the Customer workspace within the folder structure of {workspace?.label}:
        </Typography>
        <CustomTreeView
          treeData={folders}
          partnershipFolderId={inviteSupplier.host_folder_uuid}
          selectedNode={currentFolderId}
          setSelectedNode={(nodeId) => setCurrentFolderId(nodeId)}
        />
      </Box>
    )
  }, [folders, workspace, currentFolderId])

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpened}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            maxWidth: 1200
          }
        }}>
        <Box className="right-drawer">
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 34
            }}>
            <Typography variant="h5">Partnership Setup</Typography>
            <IconButton onClick={handleCloseDrawer}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="body1">
                Please select the Workspace and partner whose Partner Permissions you wish to view. If you make changes
                to these permissions, your partner will be notified.
              </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth variant="outlined" disabled>
                <InputLabel>Workspace</InputLabel>
                <Select
                  sx={{ width: '100%' }}
                  value={workspaceId}
                  label="Workspace"
                  placeholder="Workspace"
                  onChange={handleWorkspaceChange}>
                  {workspaces?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {workspace && (
            <>
              <Box paddingTop="24px" paddingBottom="24px">
                <CustomTabs
                  options={TAB_STATES}
                  onTabChange={(newValue: string) => {
                    setCurrentTab(newValue)
                  }}
                  defaultValue={currentTab}></CustomTabs>
              </Box>

              {currentTab === 'FOLDER-LOCATION' && <Box>{!!folders.length && renderFolders}</Box>}

              {currentTab === 'STATE-MAPPING' && (
                <Box>
                  <MapStates />
                </Box>
              )}

              <Box paddingTop="24px">
                <Button variant="contained" onClick={handleSaveChanges}>
                  Save Changes
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Drawer>
    </>
  )
}

export default DrawerPartnershipDetail
