import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Button
} from '@mui/material'
import React, { useEffect } from 'react'
import { updateInviteSupplier, updateModules } from '../../../../state/slices/invitation'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../../state/store'
import { useApiGetTemplates } from '../../../../hooks'

const WorkspaceSetupDetail: React.FC = () => {
  const dispatch = useDispatch()
  const { inviteSupplier, modules } = useSelector((state: RootState) => state.invitation)
  const [templates, loadTemplates] = useApiGetTemplates()

  const handleModuleChange = (event: SelectChangeEvent<string>) => {
    dispatch(updateInviteSupplier({ key: 'modules', value: [event.target.value] }))
  }

  useEffect(() => {
    if (!modules.length) loadTemplates({})
  }, [])

  useEffect(() => {
    if (templates.isLoaded) {
      dispatch(updateModules(templates.data))
    }
  }, [templates])

  return (
    <Box component="form" sx={{ flexGrow: 1 }} marginTop="24px">
      <Grid container spacing={2}>
        {/* Workspace Name Section */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Workspace Name"
            required
            variant="outlined"
            value={inviteSupplier.account_name}
            onChange={(event) => dispatch(updateInviteSupplier({ key: 'account_name', value: event.target.value }))}
          />
        </Grid>

        {/* Module Selection Section */}
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}>
          <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            Select Module Type *
          </Typography>
          <RadioGroup
            defaultValue="preBuiltModule"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}>
            <Box>
              <FormControlLabel
                value="preBuiltModule"
                control={<Radio size="medium" />}
                label={
                  <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                    Use a Papertrail Module *
                  </Typography>
                }
              />
              <Typography variant="body1" color="textSecondary">
                Selecting a Papertrail Pre-Built Module requires your Customer to customize their Workspace from
                scratch. Alternatively, you can select "Use a Custom Module" to use the configuration of one of your
                modules as a Module.
              </Typography>
            </Box>

            <Box>
              <FormControlLabel
                value="customModule"
                control={<Radio size="medium" />}
                label={
                  <Typography variant="body1" sx={{ color: '#00000061' }}>
                    Use a Custom Module
                  </Typography>
                }
                disabled
              />
              <Typography variant="body1" sx={{ color: '#00000061' }}>
                Coming soon!
              </Typography>
            </Box>
          </RadioGroup>
        </Grid>

        {/* Pre-Built Module Selection */}
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}>
          <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            Choose Papertrail Module *
          </Typography>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel>Module</InputLabel>
            <Select value={inviteSupplier.modules[0]} onChange={handleModuleChange} label="Module">
              {modules.map((module) => (
                <MenuItem value={module.value}>{module.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="outlined" onClick={() => Intercom('show')}>
            Need Other Modules?
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default WorkspaceSetupDetail
