import { ApiResponse, useApiPost, useApiGet, useApiGetMany, useApiPatch } from '@papertrail/web3-utils'
import { InviteSupplierData } from './state/slices/types'
import { Account, FolderInfo, Mapping, OptionItem, Subscription } from './types'

export function accountMapper(data): Account {
  // if the account is returned without member data, we must have employee access
  return {
    id: data.id,
    active: !data.deactivated_at,
    accountName: data.name,
    templateName: data.template ? data.template.data.name : '',
    isDemo: data.demo,
    logo: data.logo,
    isAdmin: (data.member && data.member.data.admin) || false,
    isEmployee: !!!data.member,
    member: data.member ? data.member.data : undefined,
    expiresAt: data.trial_expires_at ? data.trial_expires_at : undefined,
    deactivatedAt: data.deactivated_at ? data.deactivated_at : undefined,
    subscription: data.subscription ? subscriptionMapper(data.subscription.data) : undefined
  }
}

function subscriptionMapper(data): Subscription {
  return {
    id: data.id,
    subName: data.name,
    active: data.active,
    planName: data.package.data.code === 'base-package' ? '' : data.package.data.name,
    isTrial: data.package.data.code === 'base-package',
    deactivatedAt: data.deactivated_at ? data.deactivated_at : undefined,
    allowances: {
      users: data.members,
      records: data.records,
      unlimitedRecords: data.package.data.unlimited_records,
      viewonlyUsers: data.view_only_members,
      unlimitedViewOnlyUsers: data.view_only_members >= 1000
    },
    features: data.features.data,
    trialExpiry: data.trial_expires_at,
    supplier: data.supplier,
    type: data.type
  }
}

export const mapFolders = (data: ApiResponse): FolderInfo[] => {
  return data.data.map((folderData: ApiResponse) => ({
    id: folderData.id,
    name: folderData.name,
    shared: folderData.partner,
    children: folderData.children.data ? mapFolders(folderData.children) : null
  }))
}

function mapCountries(data): OptionItem[] {
  return data.data.map((c) => ({
    value: c.alpha_2,
    label: c.name
  }))
}

function mapStates(data): OptionItem[] {
  return [
    ...data.data.map((c) => ({
      value: c.id,
      label: c.name
    }))
  ]
}

function mapIndustries(data): OptionItem[] {
  return data.data.map((c) => ({
    value: c.id,
    label: c.name
  }))
}

function mapTemplates(data): OptionItem[] {
  return data.data.map((c) => ({
    value: c.key,
    label: c.name
  }))
}

export function useApiGetAccounts(pageSize: number) {
  const [accountsState, loadFirstPage, loadMoreAccounts] = useApiGetMany<Account>(`/accounts`, pageSize, accountMapper)
  function loadAccounts(extraParams, deactivatedOnly: boolean) {
    const includes =
      'member,subscription,subscription.package,subscription.features,template,member.user,member.member_calendar'

    loadFirstPage({
      include: includes,
      role: 'member,employee',
      sort: 'name',
      ...extraParams
    })
  }

  return [accountsState, loadAccounts, loadMoreAccounts] as const
}

export function useApiGetAccount(id: string) {
  const [accountState, getAccount] = useApiGet(`/accounts/${id}`, (data) => data)
  return [accountState, getAccount] as const
}

export function useApiGetSubAccounts() {
  const [subAccountsState, getSubAccounts] = useApiGet(`/accounts`, (data) => data)
  return [subAccountsState, getSubAccounts] as const
}

export function useApiGetAccountStates(id: string) {
  const [accountState, getAccountStates] = useApiGet(`/accounts/${id}/states`, mapStates)
  return [accountState, getAccountStates] as const
}

export function useApiGetFindSubscriptions(subscription_id: string) {
  const [foundSubscriptions, get] = useApiGet(
    `/partnerhub/${subscription_id}/partnerships/invite/subscriptions`,
    (data) => data
  )

  function findSubscriptions(email: string) {
    get({
      email
    })
  }

  return [foundSubscriptions, findSubscriptions] as const
}

export const useApiGetFolders = (accountId: string, rootFolderId: string, existing: FolderInfo[]) => {
  function mapFoldersWithExisting(data: ApiResponse): FolderInfo[] {
    return data.data.map((folderData: ApiResponse) => ({
      id: folderData.id,
      name: folderData.name,
      shared: folderData.partner,
      children: folderData.children.data ? mapFolders(folderData.children) : null
    }))
  }
  return useApiGet(`/accounts/${accountId}/folders/${rootFolderId}`, mapFoldersWithExisting)
}

export const useCountriesApi = () => {
  return useApiGet<OptionItem[]>('/countries', mapCountries)
}

export const useIndustriesApi = () => {
  return useApiGet<OptionItem[]>('/industries', mapIndustries)
}

export function useApiGetTemplates() {
  return useApiGet(`/templates/`, mapTemplates)
}

export function useApiPostInvite(subscription_id: string) {
  const [inviteState, post] = useApiPost(`/partnerhub/${subscription_id}/partnerships/invite`, (data) => data)

  function postInvite(inviteSupplierData: InviteSupplierData) {
    post(inviteSupplierData)
  }

  return [inviteState, postInvite] as const
}

export function useApiPatchInvite(subscription_id: string) {
  const [inviteState, patch] = useApiPatch(`/partnerhub/${subscription_id}/partnerships/invite`, (data) => data)

  function patchInvite(inviteMappingData: Mapping[], invitationId) {
    const mappingUpdate = {
      uuid: invitationId,
      mappings: inviteMappingData
    }
    patch(mappingUpdate)
  }

  return [inviteState, patchInvite] as const
}

export function useApiCompleteInvite(subscription_id: string) {
  const [, post] = useApiPost(`/partnerhub/${subscription_id}/partnerships/invite/complete`, (data) => data)

  function completeInvite(invitationId) {
    post({ uuid: invitationId })
  }

  return [, completeInvite] as const
}

export function useApiPatchPartnership(subscription_id: string, partnership_id: string) {
  const [partnershipState, patch] = useApiPatch(
    `/partnerhub/${subscription_id}/partnerships/${partnership_id}`,
    (data) => data
  )

  function patchPartnershup({
    mappings = [],
    host_folder_uuid = ''
  }: {
    mappings?: Mapping[]
    host_folder_uuid?: string
  }) {
    const mappingUpdate = {
      uuid: partnership_id,
      mappings,
      host_folder_uuid
    }
    patch(mappingUpdate)
  }

  return [partnershipState, patchPartnershup] as const
}
