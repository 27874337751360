import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import { Box } from '@mui/material'
import CustomPlaceholder from '../../../molecules/placeholder'
import React from 'react'
import { RootState } from '../../../../state/store'
import { useSelector } from 'react-redux'

const SendInviteDetail: React.FC = () => {
  const { inviteSupplier } = useSelector((state: RootState) => state.invitation)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: 400
      }}>
      <CustomPlaceholder
        IconComponent={AddBusinessIcon}
        title={`You have successfully sent an invitation to ${inviteSupplier.company_name}. They will receive it at ${inviteSupplier.contact.email}.`}
        buttonText="Invite New Partner"
        hideButton
      />
    </Box>
  )
}

export default SendInviteDetail
