import { ReactNode } from 'react'

type FilterType = 'filter'
type RuleType = 'rule'

export type Tag = {
  id: string
  name: string
  recordCount: number
}
export type Member = {
  id: string
  isAdmin: boolean
}

export type Filter = {
  id: string
  name: string
  recordCount: number
  profile: string
  showCount: boolean
  starred: string
  inspection: string
  rules: []
  type: FilterType
  default: boolean
  order?: number
}
export type Rule = {
  id: string
  key: string
  value: string
  active: boolean
  label: string
  sentiment: string
  type: RuleType
  operator: string
}

export type Blueprint = {
  id: string
  fields: Field[]
}
export type Field = {
  id: string
  type: string
  label: string
  key: string
  required: boolean
  position: number
}
export type Contact = {
  id: string
  name: string
  type: string
  type_id?: string
  email: string
  mobile: string
  phone: string
}

export type Frequency = {
  id: string
  name: string
  rule: string
  notify: string
  default: boolean
  order: number
  dateStartOffset: string
  options: Record<string, unknown>
  defaultOptions: {
    due: {
      due_date: boolean
      inspection_date: boolean
      next_check_at: boolean
    }
    early: {
      due_date: boolean
      inspection_date: boolean
      next_check_at: boolean
    }
    late: {
      due_date: boolean
      inspection_date: boolean
      next_check_at: boolean
    }
  }
}

export type State = {
  id: string
  name: string
  colour: string
  order: number
  isPositive: boolean
  profile: string
  shouldArchive: boolean
  recordCount: number
  default: boolean
  deleted: boolean
  deletable: boolean
  editable: boolean
}

export interface TreeNode {
  id: string
  name: string
  shared?: boolean
  children?: TreeNode[]
}

export interface OptionItem {
  label: string
  value: string
}

export interface StepItemControlOptions {
  disabled?: boolean
  onClick?: (props: any) => void
  hidded?: boolean
  text?: string
}

export interface StepItemControls {
  back: StepItemControlOptions
  next: StepItemControlOptions
}

export interface StepItem {
  label: string
  value: string | number
  hidden?: boolean
  description?: string
  component?: ReactNode
  controls?: StepItemControls
  mandatoryFields?: boolean
}

export type Account = {
  id: string
  active: boolean
  accountName: string
  templateName: string
  isDemo: boolean
  isAdmin: boolean
  isEmployee: boolean
  expiresAt?: string
  deactivatedAt?: string
  logo?: string
  member?: Member
  subscription?: Subscription
}

export type Subscription = {
  id: string
  active: boolean
  subName: string
  trialExpiry?: string
  deactivatedAt?: string
  isTrial: boolean
  allowances: Allowances
  planName: string
  features: any
  type: string
  supplier: SubscriptionSupplierData
}

export type SubscriptionSupplierData = {
  data: SubscriptionSupplier
}

export type SubscriptionSupplier = {
  id: string
  name: string
  description: string
}

export type Allowances = {
  users: number
  records: number
  viewonlyUsers: number
  unlimitedRecords: boolean
  unlimitedViewOnlyUsers: boolean
}

export type FolderInfo = {
  id: string
  name: string
  shared?: boolean
  children?: FolderInfo[]
}

export type Mapping = {
  account_uuid: string
  folders: string[]
  permissions: {
    folders: string
    records: string
    logs: string
    tasks: string
  }
  states: {
    host_state_uuid: string
    consumer_state_uuid: string
  }[]
}

export enum ManagerGridTypes {
  SENT = 'sent',
  RECEIVED = 'received',
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export type subAccount = {
  id: string
  name: string
}

export enum STEPPER_ITEMS {
  INITIAL_STATE = 0,
  CLIENT_DATA = 1,
  FOLDER_MAPPING = 2,
  CONTACT_REVIEW = 3,
  WORKSPACE_SETUP = 4,
  SEND_INVITE = 5
}
