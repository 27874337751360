/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, Button, Drawer, IconButton, Typography } from '@mui/material'
import React, { ReactNode, useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ClientDataDetail from '../detail/partnerInvitation/clientData'
import CloseIcon from '@mui/icons-material/Close'
import CustomStepper from '../stepper'
import FolderMappingDetail from '../detail/partnerInvitation/folderMapping'
import { RootState } from '../../../state/store'
import SendInviteDetail from '../detail/partnerInvitation/sendInvite'
import { StepItem, STEPPER_ITEMS } from '../../../types'
import WorkspaceSetupDetail from '../detail/partnerInvitation/workspaceSetup'
import { resetState, updateInviteSupplier } from '../../../state/slices/invitation'
import { useApiPostInvite, useApiGetFindSubscriptions } from '../../../hooks'
import { useParams } from 'react-router-dom'
import { useSnackbar } from '../../providers/snackbar'
import ContactReviewDetail from '../detail/partnerInvitation/contactReview'
import { InvitationRequesterRole } from '../../../state/slices/types'
import InitialState from '../detail/partnerInvitation/initialState'

interface IDrawerInvitePartnersProps {
  isOpened: boolean
  setIsOpened: (state: boolean) => any
  children?: ReactNode
}

const DrawerInvitePartners: React.FC<IDrawerInvitePartnersProps> = ({
  isOpened,
  setIsOpened
}: IDrawerInvitePartnersProps) => {
  const dispatch = useDispatch()
  const { id: subscriptionId } = useParams<{ id: string }>()
  const [step, setStep] = useState(STEPPER_ITEMS.CLIENT_DATA)
  const [_, sendInvite] = useApiPostInvite(subscriptionId)
  const { inviteSupplier } = useSelector((state: RootState) => state.invitation)
  const { showSnackbar } = useSnackbar()
  const [foundSubscriptions, findSubscriptions] = useApiGetFindSubscriptions(subscriptionId)
  const [validationErrors, setValidationErrors] = useState<boolean>(false)

  const handleBackStep = () => handleChangeStep(-1)
  const handleNextStep = () => handleChangeStep(1)

  useEffect(() => {
    if (foundSubscriptions.data && foundSubscriptions.isLoaded) {
      const isExistentContact = foundSubscriptions.data.data ? foundSubscriptions.data.data.length > 0 : false

      if (isExistentContact) {
        setStep(STEPPER_ITEMS.CONTACT_REVIEW)
      } else {
        setStep(STEPPER_ITEMS.FOLDER_MAPPING)
      }
    }
  }, [foundSubscriptions])

  const handleFinishInvitation = () => {
    setIsOpened(false)
    dispatch(resetState())
    setStep(STEPPER_ITEMS.CLIENT_DATA)
    window.dispatchEvent(new Event('@invites/GET_ALL'))
  }

  const handleSubmitInvitation = () => {
    try {
      sendInvite(inviteSupplier)
      setStep(STEPPER_ITEMS.SEND_INVITE)
    } catch (err) {
      showSnackbar(err.message, 'error')
    }
  }

  const handleExistingContact = (subscription = null) => {
    const subscriptionUuid = subscription ? subscription.id : null
    const as = subscription ? InvitationRequesterRole.SupplierExisting : InvitationRequesterRole.SupplierNew

    dispatch(updateInviteSupplier({ key: 'subscription_uuid', value: subscriptionUuid }))
    dispatch(updateInviteSupplier({ key: 'as', value: as }))

    setStep(STEPPER_ITEMS.FOLDER_MAPPING)
  }

  const handleReviewContact = async () => {
    /**
     * @TODO DO ANYTHING NEEDED TO GET CONTACT INFORMATION
     * IF EXISTS, PUT ON STORE AND FILL "contactReview.tsx" COMPONENT WITH DATA
     */
    findSubscriptions(inviteSupplier.contact.email)
  }

  const handleOnInvitePartnerClick = async (type) => {
    if (type === 'customer') {
      handleNextStep()
    }

    // @TODO: what to do if is a partner?
  }

  const steps = useMemo<StepItem[]>(() => {
    return [
      // {
      //   label: 'Invite new Partner',
      //   value: STEPPER_ITEMS.INITIAL_STATE,
      //   hidden: true,
      //   component: <InitialState onInvitePartnerClick={handleOnInvitePartnerClick} />,
      //   description:
      //     'When inviting a partner, you can choose the type of relationship you want to establish. You can invite a partner to be your supplier, allowing them to provide products or services to your business. Alternatively, you can invite a partner to be your Customer, enabling them to purchase your products or services. Select the appropriate type of relationship to ensure the correct permissions and roles are assigned.'
      // },
      {
        label: 'Customer Data',
        value: STEPPER_ITEMS.CLIENT_DATA,
        component: (
          <ClientDataDetail
            onValidationErrors={(hasErrors) => {
              setValidationErrors(Object.values(hasErrors).some((error) => error !== ''))
            }}
          />
        ),
        description: `Enter your Customer's information to send a partnership invitation. They will be notified once you have extended an invitation to them. You can modify this information later if necessary.`,
        mandatoryFields: true,
        controls: {
          back: {
            hidded: true
          },
          next: {
            disabled:
              !inviteSupplier.contact ||
              !inviteSupplier.contact.first_name.length ||
              !inviteSupplier.contact.last_name.length ||
              !inviteSupplier.contact.email.length ||
              !inviteSupplier.contact.phone_number.length ||
              !inviteSupplier.contact.country_code ||
              !inviteSupplier.company_name.length ||
              !inviteSupplier.industry_uuid ||
              validationErrors,
            onClick: handleReviewContact
          }
        }
      },
      {
        label: 'Customer Folder Location',
        value: STEPPER_ITEMS.FOLDER_MAPPING,
        component: <FolderMappingDetail />,
        description:
          'Select the workspace and folder your customers records are in. You can access the customers workspace and use your own checklists, tasks and certificates',
        controls: {
          back: {
            onClick: handleBackStep
          },
          next: {
            disabled: !inviteSupplier.folder_uuid,
            onClick: () => {
              inviteSupplier.subscription_uuid ? handleSubmitInvitation() : setStep(STEPPER_ITEMS.WORKSPACE_SETUP)
            }
          }
        }
      },
      {
        label: 'Contact Review',
        value: STEPPER_ITEMS.CONTACT_REVIEW,
        hidden: true,
        component: (
          <ContactReviewDetail foundSubscriptions={foundSubscriptions} onFinishInvitation={handleExistingContact} />
        ),
        description: 'We have identified existing Papertrail Customers from the contact information provided.',
        controls: {
          back: {
            onClick: () => setStep(STEPPER_ITEMS.CLIENT_DATA)
          },
          next: {
            hidded: true
          }
        }
      },
      {
        label: 'Workspace Setup',
        value: STEPPER_ITEMS.WORKSPACE_SETUP,
        component: <WorkspaceSetupDetail />,
        description:
          "Set up your Customer's Workspace by selecting the Module. You will be able to access this workspace and update the shared records.",
        controls: {
          back: {
            onClick: () => {
              updateInviteSupplier({ key: 'subscription_uuid', value: null })
              setStep(STEPPER_ITEMS.CLIENT_DATA)
            }
          },
          next: {
            disabled: !inviteSupplier.account_name?.length && !inviteSupplier.modules?.length,
            onClick: () => handleSubmitInvitation()
          }
        }
      },
      {
        label: 'Success!',
        value: STEPPER_ITEMS.SEND_INVITE,
        component: <SendInviteDetail />,
        description: "Your invitation has been sent. You can check the status in the 'Sent' tab in the Partners Page.",
        controls: {
          back: {
            hidded: true
          },
          next: {
            text: 'Close',
            onClick: handleFinishInvitation
          }
        }
      }
    ]
  }, [inviteSupplier, validationErrors, foundSubscriptions])

  const selectedStep = useMemo(() => {
    return steps.find((item) => item.value === step)
  }, [steps, step])

  const handleChangeStep = (nextStep: number) => {
    const nextStepIndex = step + nextStep
    if (nextStepIndex < 0 || nextStepIndex > steps.length) return false
    setStep((currentStep) => currentStep + nextStep)
  }

  const handleCloseDrawer = () => {
    dispatch(resetState())
    setIsOpened(false)
    setStep(STEPPER_ITEMS.CLIENT_DATA)
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpened}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            maxWidth: 1200
          }
        }}>
        <Box className={'right-drawer'}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 34
            }}>
            <Typography variant="h5">
              {selectedStep.value === STEPPER_ITEMS.INITIAL_STATE ? 'Invite new Partner' : 'Invite New Customer'}
            </Typography>
            <IconButton onClick={handleCloseDrawer}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '100%' }}>
              {selectedStep.value !== STEPPER_ITEMS.INITIAL_STATE && (
                <Typography variant="h6">{selectedStep.label}</Typography>
              )}
              <Typography variant="body1">{selectedStep.description}</Typography>
              {selectedStep.mandatoryFields && (
                <Typography marginTop={1} marginBottom={1} variant="body1">
                  Fields marked with * are mandatory.
                </Typography>
              )}
            </Box>
            {selectedStep.value !== STEPPER_ITEMS.INITIAL_STATE && (
              <Box sx={{ width: '100%' }}>
                <CustomStepper items={steps} activeStep={step} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                  {!selectedStep.controls.back.hidded && (
                    <Button
                      disabled={selectedStep.controls.back.disabled}
                      variant="outlined"
                      onClick={selectedStep.controls.back.onClick}>
                      {selectedStep.controls.back.text || 'Back'}
                    </Button>
                  )}
                  {!selectedStep.controls.next.hidded && (
                    <Button
                      disabled={selectedStep.controls.next.disabled}
                      variant="contained"
                      color="primary"
                      onClick={selectedStep.controls.next.onClick}>
                      {selectedStep.controls.next.text || 'Next'}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </Box>

          {selectedStep.component}
        </Box>
      </Drawer>
    </>
  )
}

export default DrawerInvitePartners
