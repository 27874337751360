import { Alert, Snackbar } from '@mui/material'
import React, { ReactNode, createContext, useContext, useState } from 'react'

interface SnackbarContextType {
  showSnackbar: (message: string, severity: 'success' | 'info' | 'warning' | 'error') => void
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined)

interface SnackbarProviderProps {
  children: ReactNode
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [snack, setSnack] = useState<{
    message: string
    severity: 'success' | 'info' | 'warning' | 'error'
    open: boolean
  }>({ message: '', severity: 'success', open: false })

  const showSnackbar = (message: string, severity: 'success' | 'info' | 'warning' | 'error') => {
    setSnack({ message, severity, open: true })
  }

  const handleClose = () => {
    setSnack((prev) => ({ ...prev, open: false }))
  }

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
        open={snack.open}
        onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={snack.severity}
          variant="filled"
          sx={{ width: '100%', fontSize: '12px' }}>
          {snack.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext)
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }
  return context
}
