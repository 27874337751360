import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, FieldArray, Form } from 'formik'
import { RootState } from 'src/state/store'
import { useApiGetAccountStates } from '../../../../hooks'
import { updateMappingState } from '../../../../state/slices/invitation'

const MapStates = () => {
  const dispatch = useDispatch()
  const { workspaceId, inviteSupplier } = useSelector((state: RootState) => state.invitation)
  const [accountStatesState, loadAccountStates] = useApiGetAccountStates(workspaceId)

  const [selectedValuesByAccount, setSelectedValuesByAccount] = useState<{ [key: number]: string[] }>({})

  useEffect(() => {
    if (workspaceId) {
      loadAccountStates({})
    }
  }, [workspaceId])

  const matchConsumerStateByName = (supplierStateName, consumerStates) => {
    const matchedState = consumerStates.find((state) => state.name === supplierStateName)
    return matchedState ? matchedState.id : ''
  }

  const initialValues =
    inviteSupplier?.mappings && accountStatesState.data
      ? {
          accounts: inviteSupplier.consumerAccounts.map((item, index) => ({
            account: item.name,
            states: accountStatesState.data.map((supplierState) => ({
              host_state_uuid: supplierState.value,
              consumer_state_uuid:
                Object.keys(inviteSupplier.mappings[index].states).length > 0
                  ? inviteSupplier.mappings?.[index]?.states?.find(
                      (item) => item.host_state_uuid === supplierState.value
                    )?.consumer_state_uuid || 'null'
                  : matchConsumerStateByName(supplierState.label, item.states.data) || ''
            }))
          }))
        }
      : { accounts: [] }

  useEffect(() => {
    if (initialValues.accounts.length > 0 && Object.keys(selectedValuesByAccount).length === 0) {
      const initialSelections = initialValues.accounts.reduce((acc, account, index) => {
        const selectedValues = account.states.map((state) => state.consumer_state_uuid).filter((value) => value !== '')
        return { ...acc, [index]: selectedValues }
      }, {})
      setSelectedValuesByAccount(initialSelections)

      initialValues.accounts.forEach((account) => {
        const updatedStates = account.states.map((state) =>
          state.consumer_state_uuid === 'null' ? { ...state, consumer_state_uuid: '' } : state
        )

        const accountUuid = inviteSupplier.consumerAccounts.find((consAcc) => consAcc.name === account.account).id

        dispatch(
          updateMappingState({
            account_uuid: accountUuid,
            newState: updatedStates
          })
        )
      })
    }
  }, [initialValues])

  const handleFormikChange = (values) => {
    const selections = values.accounts.reduce((acc, account, index) => {
      const selectedValues = account.states.map((state) => state.consumer_state_uuid).filter((value) => value !== '')
      return { ...acc, [index]: selectedValues }
    }, {})
    setSelectedValuesByAccount(selections)

    // Update all accounts and ensure mappings are dispatched correctly
    values.accounts.forEach((account) => {
      const updatedStates = account.states.map((state) =>
        state.consumer_state_uuid === 'null' ? { ...state, consumer_state_uuid: '' } : state
      )

      const accountUuid = inviteSupplier.consumerAccounts.find((consAcc) => consAcc.name === account.account).id

      dispatch(
        updateMappingState({
          account_uuid: accountUuid,
          newState: updatedStates
        })
      )
    })
  }

  const filterOptions = (options, selectedValues, currentSelected) => {
    return options.filter(
      (option) =>
        option.id === currentSelected || // Include the current selection
        !selectedValues.includes(option.id) // Exclude already selected options
    )
  }

  const handleSubmit = (values) => {
    handleFormikChange(values)
  }

  if (accountStatesState.data && accountStatesState.data.length > 0 && initialValues) {
    return (
      <div>
        <Formik initialValues={initialValues} onSubmit={(values) => handleSubmit(values)} validateOnChange={false}>
          {(formikProps) => (
            <Form>
              <FieldArray name="accounts">
                {() => (
                  <>
                    {formikProps.values.accounts.map((account, accountIndex) => (
                      <Accordion key={accountIndex} defaultExpanded={accountIndex === 0}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${accountIndex}-content`}
                          id={`panel${accountIndex}-header`}>
                          <Typography variant="h6">{account.account}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box flex={1}>
                              <Typography variant="body1">Your States</Typography>
                            </Box>
                            <Box flex={1} marginLeft={'41px'}>
                              <Typography variant="body1">Customer States</Typography>
                            </Box>
                          </Box>
                          <FieldArray name={`accounts.${accountIndex}.states`}>
                            {() => (
                              <div>
                                {account.states.map((state, stateIndex) => (
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems={'center'}
                                    key={stateIndex}
                                    marginTop="20px"
                                    gap={'16px'}>
                                    {/* Supplier State */}
                                    <TextField
                                      disabled
                                      value={
                                        accountStatesState.data.find((option) => option.value === state.host_state_uuid)
                                          ?.label || ''
                                      }
                                      label="Supplier State"
                                      variant="outlined"
                                      fullWidth
                                    />

                                    <Box>{/* Optional Arrow */}</Box>

                                    {/* Consumer State Dropdown */}
                                    <TextField
                                      select
                                      name={`accounts.${accountIndex}.states.${stateIndex}.consumer_state_uuid`}
                                      value={
                                        formikProps.values.accounts[accountIndex].states[stateIndex].consumer_state_uuid
                                      }
                                      onChange={(e) => {
                                        formikProps.setFieldValue(
                                          `accounts.${accountIndex}.states.${stateIndex}.consumer_state_uuid`,
                                          e.target.value
                                        )
                                        formikProps.handleChange(e)
                                        formikProps.handleSubmit()
                                      }}
                                      label="Select a customer state"
                                      variant="outlined"
                                      fullWidth>
                                      {/* Add "Do Not Map" Option */}
                                      <MenuItem value="null">Do Not Map</MenuItem>
                                      {filterOptions(
                                        inviteSupplier.consumerAccounts[accountIndex].states.data,
                                        selectedValuesByAccount[accountIndex] || [],
                                        formikProps.values.accounts[accountIndex].states[stateIndex].consumer_state_uuid
                                      ).map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Box>
                                ))}
                              </div>
                            )}
                          </FieldArray>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </div>
    )
  } else {
    return null
  }
}

export default MapStates
