import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { RootState } from '../../../../state/store'
import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentClientWorkspace, updateMapping } from '../../../../state/slices/invitation'

type Props = {
  edit?
}

const PartnerPermissions = (props: Props) => {
  const { edit } = props
  const dispatch = useDispatch()
  const { currentWorkspace, selectedWorkspaces, inviteSupplier } = useSelector((state: RootState) => state.invitation)
  const options = [
    { value: 'view', label: 'Can View' },
    { value: 'add', label: 'Can Add' },
    { value: 'edit', label: 'Can Edit' }
  ]

  const handlePermissionChange = (key, event) => {
    if (event.target.value && event.target.value !== 'null') {
      dispatch(
        updateCurrentClientWorkspace({
          ...currentWorkspace,
          permissions: {
            ...currentWorkspace.permissions,
            [key]: event.target.value
          }
        })
      )
    }
  }

  useEffect(() => {
    if (currentWorkspace && edit) {
      dispatch(
        updateMapping(
          inviteSupplier.mappings.map((workspace) => {
            if (workspace.account_uuid === currentWorkspace.account_uuid) {
              return { ...currentWorkspace }
            }

            return workspace
          })
        )
      )
    }
  }, [currentWorkspace])

  return (
    <>
      {selectedWorkspaces.length && currentWorkspace && (
        <>
          <Box>
            {!edit && (
              <Box>
                <Typography variant="h5">{`You are currently setting up ${
                  selectedWorkspaces.find((workspace) => workspace.id === currentWorkspace.account_uuid).name
                }`}</Typography>
              </Box>
            )}
          </Box>

          <Box></Box>
          <Box>
            <Box paddingTop="24px">
              <FormControl variant="outlined" required>
                <InputLabel>Folder Permissions</InputLabel>
                <Select
                  sx={{ width: '220px' }}
                  value={currentWorkspace.permissions.folders}
                  label="Folder Permissions"
                  placeholder="Permissions"
                  onChange={(value) => handlePermissionChange('folders', value)}>
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box paddingTop="24px">
              <FormControl variant="outlined" required>
                <InputLabel>Record Permissions</InputLabel>
                <Select
                  sx={{ width: '220px' }}
                  value={currentWorkspace.permissions.records}
                  label="Record Permissions"
                  placeholder="Permissions"
                  onChange={(value) => handlePermissionChange('records', value)}>
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box paddingTop="24px">
              <FormControl variant="outlined" required>
                <InputLabel>Inspection Permissions</InputLabel>
                <Select
                  sx={{ width: '220px' }}
                  value={currentWorkspace.permissions.logs}
                  label="Inspection Permissions"
                  placeholder="Permissions"
                  onChange={(value) => handlePermissionChange('logs', value)}>
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* <Box paddingTop="24px">
              <FormControl variant="outlined" required>
                <InputLabel>Task Permissions</InputLabel>
                <Select
                  sx={{ width: '220px' }}
                  value={currentWorkspace.permissions.tasks}
                  label="Task Permissions"
                  placeholder="Permissions"
                  onChange={(value) => handlePermissionChange('tasks', value)}>
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box> */}
          </Box>
        </>
      )}
    </>
  )
}

export default PartnerPermissions
