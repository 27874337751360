/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Drawer, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { ReactNode, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import {
  resetState,
  updateCurrentClientWorkspace,
  updateFolders,
  updateMapping,
  updateRootFolderId,
  updateSelectedWorkspaces
} from '../../../state/slices/invitation'
import { useSnackbar } from '../../providers/snackbar'
import { RootState } from '../../../state/store'
import { useParams } from 'react-router-dom'
import PartnerPermissions from '../detail/partnershipSetup/permissions'
import CustomTabs from '../../molecules/tab'
import CustomTreeView from '../threeview'
import { useApiGetAccount, useApiGetFolders, useApiPatchPartnership } from '../../../hooks'

interface IDrawerInvitePartnersProps {
  isOpened: boolean
  setIsOpened: (state: boolean) => any
  children?: ReactNode
}

const DrawerEditPartnerAccess: React.FC<IDrawerInvitePartnersProps> = ({
  isOpened,
  setIsOpened
}: IDrawerInvitePartnersProps) => {
  const { showSnackbar } = useSnackbar()
  const { id: subscriptionId } = useParams<{ id: string }>()
  const { inviteSupplier, selectedWorkspaces, folders, currentWorkspace, rootFolderId } = useSelector(
    (state: RootState) => state.invitation
  )
  const dispatch = useDispatch()
  const TAB_STATES = [
    { value: 'FOLDERS', label: 'Folder Access Permissions' },
    { value: 'PERMISSIONS', label: 'Partner Permissions' }
  ]
  const [currentTab, setCurrentTab] = useState<string>('FOLDERS')
  const [accountState, loadAccount] = useApiGetAccount(currentWorkspace?.account_uuid)
  const [folderList, loadFolders] = useApiGetFolders(currentWorkspace?.account_uuid, rootFolderId, folders)
  const [partnership, updatePartnership] = useApiPatchPartnership(subscriptionId, inviteSupplier.id)

  const handleFinishPartnership = () => {
    setIsOpened(false)
    dispatch(resetState())
  }

  const handleUpdatePartnership = async () => {
    await updatePartnership(inviteSupplier)
  }

  useEffect(() => {
    if (partnership.isLoaded) {
      setTimeout(() => {
        showSnackbar(`You have successfully updated your partnership.`, 'success')
      }, 300)
      window.dispatchEvent(new Event('@partnerships/GET_ALL'))
      handleFinishPartnership()
    }
  }, [partnership.isLoaded])

  useEffect(() => {
    if (partnership.isError) {
      showSnackbar(`There has been a problem updating your partnership.`, 'error')
    }
  }, [partnership.isError])

  const handleCloseDrawer = () => {
    dispatch(resetState())
    setIsOpened(false)
  }

  useEffect(() => {
    if (
      currentWorkspace &&
      currentWorkspace.account_uuid &&
      (!accountState.isLoaded || accountState.data?.data?.id !== currentWorkspace.account_uuid) &&
      isOpened
    )
      loadAccount({ include: 'folder', role: 'member,owner,employee' })
  }, [currentWorkspace, isOpened])

  useEffect(() => {
    if (accountState.isLoaded && accountState.data?.data?.id === currentWorkspace?.account_uuid && isOpened) {
      dispatch(updateRootFolderId(accountState.data?.data?.folder?.data?.id))
      loadFolders({ include: `descendants`, format: 'tree' })
    }
  }, [accountState.isLoaded, isOpened])

  useEffect(() => {
    if (folderList.isLoaded && folderList.data && isOpened) {
      dispatch(updateFolders(folderList.data))
    }
  }, [folderList.isLoaded, isOpened])

  useEffect(() => {
    if (inviteSupplier && inviteSupplier.consumerAccounts && isOpened) {
      dispatch(
        updateSelectedWorkspaces(
          inviteSupplier.consumerAccounts.map((account) => {
            return {
              id: account.id,
              name: account.name
            }
          })
        )
      )
      if (!currentWorkspace) {
        dispatch(updateCurrentClientWorkspace(inviteSupplier.mappings[0]))
      }
    }
  }, [inviteSupplier, isOpened])

  const handleWorkspaceChange = (event) => {
    dispatch(
      updateCurrentClientWorkspace(
        inviteSupplier.mappings.find((workspace) => workspace.account_uuid === event.target.value)
      )
    )
  }

  const setSelectedFolders = (e: string[]) => {
    const workspace = {
      ...currentWorkspace,
      folders: e
    }

    dispatch(updateCurrentClientWorkspace(workspace))
    dispatch(
      updateMapping(
        inviteSupplier.mappings.map((myWorkspace) => {
          if (myWorkspace.account_uuid === currentWorkspace.account_uuid) {
            return workspace
          }

          return myWorkspace
        })
      )
    )
  }

  const renderFolders = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          marginTop: 2
        }}>
        <Typography
          variant="body1"
          sx={{
            color: 'rgba(0, 0, 0, 0.87)'
          }}>
          Folders on{' '}
          {inviteSupplier.consumerAccounts &&
            currentWorkspace &&
            inviteSupplier.consumerAccounts.length &&
            inviteSupplier.consumerAccounts.find((workspace) => workspace.id === currentWorkspace.account_uuid).name}
        </Typography>
        <CustomTreeView
          title={`Folders on ${
            inviteSupplier.consumerAccounts &&
            currentWorkspace &&
            inviteSupplier?.consumerAccounts.find((workspace) => workspace.id === currentWorkspace.account_uuid).name
          }`}
          treeData={folders}
          selectedNodeArray={currentWorkspace?.folders}
          multiSelect={true}
          setSelectedNodeArray={setSelectedFolders}
          showShared
        />
      </Box>
    )
  }, [currentWorkspace, folders, inviteSupplier])

  return (
    <>
      <Drawer anchor="right" open={isOpened} onClose={handleCloseDrawer}>
        <Box sx={{ maxWidth: '100vw', p: '5%', display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 34,
              marginBottom: '20px'
            }}>
            <Typography variant="h5">Partnership Details</Typography>
            <IconButton onClick={handleCloseDrawer}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 34
            }}>
            <Typography variant="body1">
              Please Select the Workspace to view the Partner Permissions. If you make changes to this access, your
              partner will be notified.
            </Typography>
            <FormControl fullWidth variant="outlined" required>
              <Select onChange={handleWorkspaceChange} label="Workspace" value={currentWorkspace?.account_uuid}>
                {inviteSupplier.consumerAccounts &&
                  inviteSupplier.consumerAccounts.map((account) => (
                    <MenuItem value={account.id}>{account.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box paddingTop="24px">
            {currentWorkspace && (
              <CustomTabs
                options={TAB_STATES}
                onTabChange={(newValue: string) => {
                  setCurrentTab(newValue)
                }}
                defaultValue={currentTab}></CustomTabs>
            )}
          </Box>

          <Box>{currentTab === 'PERMISSIONS' && <PartnerPermissions edit />}</Box>
          <Box>
            {currentTab === 'FOLDERS' &&
              inviteSupplier?.consumerAccounts &&
              inviteSupplier?.consumerAccounts.length &&
              currentWorkspace && <Box>{renderFolders}</Box>}
          </Box>
          <Box>
            {currentWorkspace && (
              <Button
                variant="contained"
                children={'Save Changes'}
                data-testid="update-partnership"
                onClick={handleUpdatePartnership}
              />
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default DrawerEditPartnerAccess
