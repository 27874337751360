/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useMemo, useState, useEffect, useCallback } from 'react'
import { useSnackbar } from '../../../components/providers/snackbar'
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material'
import { StepItem } from 'src/types'
import { CloseIcon } from '@papertrail/styleguide'
import CustomStepper from '../stepper'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../state/store'
import { resetCurrentClientWorkspace, resetState, updateCurrentClientWorkspace } from '../../../state/slices/invitation'
import Workspaces from '../detail/partnershipSetup/workspaces'
import PartnerPermissions from '../detail/partnershipSetup/permissions'
import Folders from '../detail/partnershipSetup/folders'
import WorkspaceReview from '../detail/partnershipSetup/workspaceReview'
import InviteSent from '../detail/partnershipSetup/inviteSent'
import { useParams } from 'react-router-dom'
import { useApiPatchInvite } from '../../../hooks'

interface IDrawerPartnershipSetupProps {
  isOpened: boolean
  setIsOpened: (state: boolean) => any
  children?: ReactNode
}

enum STEPPER_ITEMS {
  CHOOSE_WORKSPACES = 0,
  PERMISSIONS = 1,
  FOLDERS = 2,
  REVIEW = 3,
  SEND_INVITE = 4
}

const DrawerPartnershipSetup: React.FC<IDrawerPartnershipSetupProps> = ({
  isOpened,
  setIsOpened
}: IDrawerPartnershipSetupProps) => {
  const dispatch = useDispatch()
  const { inviteSupplier, selectedWorkspaces, currentWorkspace, samePermissions } = useSelector(
    (state: RootState) => state.invitation
  )
  const [step, setStep] = useState(STEPPER_ITEMS.CHOOSE_WORKSPACES)
  const { showSnackbar } = useSnackbar()
  const handleBackStep = () => handleChangeStep(-1)
  const [initial, setInitial] = useState(true)
  const [currentWorkspaceName, setCurrentWorkspaceName] = useState(
    selectedWorkspaces.find((workspace) => workspace.id === currentWorkspace?.account_uuid)?.name || null
  )
  const [review, setReview] = useState(false)
  const { id: subscriptionId } = useParams<{ id: string }>()

  const [_, updateInvite] = useApiPatchInvite(subscriptionId)
  const handleNextStep = () => {
    if (step === 0 && initial === true) {
      if (inviteSupplier.mappings.length > 1) {
        return setInitial(false)
      } else {
        return handleChangeStep(1)
      }
    }
    if (step === 2 && review === false && inviteSupplier.mappings.length > 1) {
      return setReview(true)
    }
    if (step === 2 && review === true) {
      return selectNextWorkspace()
    } else {
      return handleChangeStep(1)
    }
  }

  useEffect(() => {
    if (currentWorkspace && selectedWorkspaces) {
      setCurrentWorkspaceName(
        selectedWorkspaces.find((workspace) => workspace.id === currentWorkspace.account_uuid).name
      )
    }
  }, [currentWorkspace])

  const handleFinishInvitation = () => {
    setIsOpened(false)
    dispatch(resetState())
    setStep(STEPPER_ITEMS.CHOOSE_WORKSPACES)
    window.dispatchEvent(new Event('@invites/GET_ALL'))
  }

  const handleUpdateInvitation = () => {
    try {
      updateInvite(inviteSupplier.mappings, inviteSupplier.id)
      handleNextStep()
    } catch (err) {
      showSnackbar(err.message, 'error')
    }
  }
  const handleChangeStep = (nextStep: number) => {
    const nextStepIndex = step + nextStep
    if (nextStepIndex < 0 || nextStepIndex > steps.length) return false
    setStep(nextStepIndex)
  }

  const selectNextWorkspace = useCallback(() => {
    const currentMapping = inviteSupplier.mappings.find(
      (workspace) => workspace.account_uuid === currentWorkspace.account_uuid
    )
    if (currentMapping?.folders && currentMapping.folders.length > 0) {
      const currentIndex = inviteSupplier.mappings.findIndex(
        (workspace) => workspace.account_uuid === currentWorkspace.account_uuid
      )

      if (currentIndex + 1 === inviteSupplier.mappings.length) {
        // Reset workspace and go to the next step
        dispatch(resetCurrentClientWorkspace())
        handleChangeStep(1)
      } else {
        // Move to the next workspace in the list
        dispatch(updateCurrentClientWorkspace(inviteSupplier.mappings[currentIndex + 1]))

        if (samePermissions === true) {
          setReview(false)
        } else {
          setReview(false)
          handleChangeStep(-1)
        }
      }
    }
  }, [inviteSupplier.mappings, currentWorkspace, samePermissions, handleChangeStep])

  const steps = useMemo<StepItem[]>(() => {
    return [
      {
        label: 'Choose Workspace(s)',
        value: STEPPER_ITEMS.CHOOSE_WORKSPACES,
        component: <Workspaces initial={initial} />,
        description: initial
          ? `Please select the Workspaces you want to collaborate with your partner, ${inviteSupplier?.host_contact?.company_name}.`
          : `You have selected multiple Workspaces to partner with  ${inviteSupplier?.host_contact?.company_name}. Would you like to grant the same permissions to your partner for all Workspaces, or grant specific permissions for each Workspace?`,
        controls: {
          back: {
            hidded: true
          },
          next: {
            onClick: handleNextStep,
            disabled: !selectedWorkspaces || selectedWorkspaces.length === 0
          }
        }
      },
      {
        label: 'Permissions',
        value: STEPPER_ITEMS.PERMISSIONS,
        component: <PartnerPermissions />,
        description: `Choose the permissions you'd like to grant your partner for collaborating on ${currentWorkspaceName}. You can always change these settings later.`,
        controls: {
          back: {
            onClick: handleBackStep
          },
          next: {
            onClick: handleNextStep,
            disabled:
              !currentWorkspace ||
              !currentWorkspace.permissions?.folders ||
              !currentWorkspace.permissions?.records ||
              !currentWorkspace.permissions.logs
          }
        }
      },
      {
        label: 'Folders',
        value: STEPPER_ITEMS.FOLDERS,
        component: <Folders review={review} />,
        description: `Select the folders your partner can view on ${currentWorkspaceName}. You can always change these settings later.`,
        controls: {
          back: {
            onClick: handleBackStep
          },
          next: {
            onClick: handleNextStep,
            disabled: !currentWorkspace?.folders || !currentWorkspace?.folders?.length
          }
        }
      },
      {
        label: 'Review',
        value: STEPPER_ITEMS.REVIEW,
        component: <WorkspaceReview />,
        description: `Review the partner permissions you are about to grant to ${inviteSupplier?.host_contact?.company_name} in the selected Workspace(s).`,
        controls: {
          back: {
            onClick: handleBackStep
          },
          next: {
            onClick: handleUpdateInvitation
          }
        }
      },
      {
        label: 'Success!',
        value: STEPPER_ITEMS.SEND_INVITE,
        component: <InviteSent />,
        description: `Your invitation has been sent. You can check the status in the "Sent" tab in the Partners Page.`,
        controls: {
          back: {
            hidded: true,
            onClick: handleBackStep
          },
          next: {
            text: 'Close',
            onClick: handleFinishInvitation
          }
        }
      }
    ]
  }, [initial, review, step, currentWorkspace, inviteSupplier])

  const selectedStep = useMemo(() => {
    return steps.find((item) => item.value === step)
  }, [steps, step])

  const handleCloseDrawer = () => {
    dispatch(resetState())
    setIsOpened(false)
    setStep(STEPPER_ITEMS.CHOOSE_WORKSPACES)
  }
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpened}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            maxWidth: 1200
          }
        }}>
        <Box className="right-drawer">
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 34
            }}>
            <Typography variant="h5">Partnership Details</Typography>
            <IconButton onClick={handleCloseDrawer}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>

          <Box sx={{ display: 'flex' }}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="h6">{selectedStep.label}</Typography>
              <Typography variant="body1">{selectedStep.description}</Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <CustomStepper items={steps} activeStep={step} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
                {!selectedStep.controls.back.hidded && (
                  <Button
                    disabled={selectedStep.controls.back.disabled}
                    variant="outlined"
                    onClick={selectedStep.controls.back.onClick}>
                    {selectedStep.controls.back.text || 'Back'}
                  </Button>
                )}
                {!selectedStep.controls.next.hidded && (
                  <Button
                    disabled={selectedStep.controls.next.disabled}
                    variant="contained"
                    color="primary"
                    onClick={selectedStep.controls.next.onClick}>
                    {selectedStep.controls.next.text || 'Next'}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>

          {selectedStep.component}
        </Box>
      </Drawer>
    </>
  )
}

export default DrawerPartnershipSetup
